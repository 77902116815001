export default {
	TARGET_YEAR: 2024,
	TARGET_MONTH: 1,
	IS_YEAR_CLOSED: true,
	MAINTENANCE_MODE: false,

	// stats
	ENABLE_REGION_STATS: true,
	ENABLE_PAX_STATS: true,
	ENABLE_PACING_STATS: true,
}
